import "../../Style/Alo/MainAlo.css";
import what from "../../images/Alo/whatsapp.png";
import tel from "../../images/Alo/telegram.png";
import ig from "../../images/Alo/instagram.png";
import shot from "../../images/Alo/shot.png";
import Ellipse14 from "../../images/Alo/Ellipse14.png";
import logoFaraz from "../../images/Alo/logoFaraz.png";
import back1b from "../../images/Navbar/back1.png";
import Ellipse1 from "../../images/Navbar/Ellipse1.png";
import Ellipse2 from "../../images/Navbar/Ellipse2.png";
import Pic from "../../images/Hero/pic.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const MainAbout = () => {
  useEffect(()=>{
    window.scrollTo(0, 0); // حرکت به بالای صفحه
  },[])
  return (
    <main>
      <div>
        <img className="Ellipse1_2" src={Ellipse1} alt="Ellipse1" />
        <img className="Ellipse2_2" src={Ellipse2} alt="Ellipse2" />
        <img className="back1b" src={back1b} alt="back1" />
        <img className="Ellipse14" src={Ellipse14} alt="Ellipse14" />
        <img className="logoFaraz" src={logoFaraz} alt="logoFaraz" style={{zIndex:1000}} />
        <div className="marginTopAlo">
          <img className="shot" src={Pic} alt="shot" />
          <div className="AloText">
            <h3 className="titleAlo titleAlo2">مُعرفی</h3>
            <p className="ContentAlo ContentAlo2" dir="rtl">
              فرازکارت یک کارت ویزیت الکترونیکی و هوشمنده که از فناوری ارتباط
              نزدیک (NFC) ، QRcode برای انتقال اطلاعات استفاده میکنه و یه جورایی
              نسل جدید کارت ویزیته که هم مقرون به صرفه و اقتصادیه و هم جایگزین
              کارت ویزیت های کاغذی و قدیمی و... هست.
              <br />
              ابعاد این کارت هیچ فرقی با کارت بانکی نداره میتونه یه سری اطلاعات
              مهم نظیر نام ، شماره تلفن ، آدرس ایمیل ، وبسایت آدرس شبکه های
              اجتماعی و... رو ذخیره کنه.
            </p>
          </div>
        </div>
        <div className="AloText2" dir="rtl">
          <h3 className="titleAlo titleAlo2">چطور کار میکنه ؟</h3>
          <p className="ContentAlo ContentAlo2" dir="rtl">
            فرض کنین که یک فرازکارت دارید که تمام اطلاعات مورد نیازتون رو توی
            خودش ذخیره کرده ، برای استفاده از این کارت باید اونو به پشت تلفن
            همراه طرف مقابل نزدیک کنین .در این صورت ، تلفن همراه طرف مقابلتون
            (مشتری ، دوست ، همکار یا ...) اطلاعات شمارو دریافت میکنه.
          </p>
          <h3 className="titleAlo titleAlo2">انتخاب طرح</h3>
          <p className="ContentAlo ContentAlo2" dir="rtl">
            شما میتونین موقع تهیه فرازکارت هم از طرح های پیشفرض موجود یه طرحی رو
            انتخاب کنین و هم طرح مورد نظر خودتون رو آپلود کنین تا ما واسطون با
            بالا ترین کیفیت چاپ کنیم.
          </p>
          <h3 className="titleAlo titleAlo2">قابلیت ویرایش برای همیشه</h3>
          <p className="ContentAlo ContentAlo2" dir="rtl">
            هر لحظه و هر مکانی و در هر شرایطی فقط با اتصال به اینترنت میتونین
            اطلاعات موجود روی کارتتون رو ویرایش کنین.
          </p>
          <h3 className="titleAlo titleAlo2">یکبار برای همیشه</h3>
          <p className="ContentAlo ContentAlo2" dir="rtl">
            با تهیه فرازکارت میتونی کلی توی هزینه هاتون صرفه جویی کنی و دیگه دور
            چاپ تعداد بالای کارت ویزیت کاغذی رو خط بکشی.
          </p>
          <h3 className="titleAlo titleAlo2">پنجاه سال ماندگاری</h3>
          <p className="ContentAlo ContentAlo2" dir="rtl">
            اطلاعات روی این کارت ویزیت تا 50 سال ماندگاری داره و خودشم ضد آبه ،
            یعنی تا حالا حالا ها خراب شدنی نیست.
          </p>
          <h3 className="titleAlo titleAlo2">دوست محیط زیست</h3>
          <p className="ContentAlo ContentAlo2" dir="rtl">
            وقتی همگام با تکنولوژی قدم برداری طبیعتا ، طبیعت هم یه نفس راحتی
            میکشه و درختای کمتری قطع میشن و دیگه آروم آروم روش های سنتی تبلیغات
            و کارت ویزیت کاغذی به کل فراموش میشن.
          </p>
          <br />
          <br />
          <p className="ContentAlo ContentAlo2" id="shoppingLink" dir="rtl">
            برای سفارش فرازکارت با طرح و رنگ دلخواه همین حالا روی دکمه {" "}
            <Link to="/Login">خرید</Link> کلیک کن
          </p>
        </div>
      </div>
    </main>
  );
};
export default MainAbout;

import React, { useEffect, useState } from "react";
import "./UserPage.css";
import MenuPage from "../MenuPage/MenuPage";
import { SlArrowLeft } from "react-icons/sl";
//logos

import aparatIcon from "../../images/logos/aparat.png";
import baleIcon from "../../images/logos/bale.png";
import facebookIcon from "../../images/logos/facebook.png";
import gapIcon from "../../images/logos/gap.png";
import instagramIcon from "../../images/logos/instagram.png";
import itaIcon from "../../images/logos/ita.png";
import iGapIcon from "../../images/logos/aigap.png";
import linkedinIcon from "../../images/logos/linkedin.png";
import robikaIcon from "../../images/logos/robika.png";
import skypeIcon from "../../images/logos/skype.png";
import sorushIcon from "../../images/logos/sorush.png";
import telegramIcon from "../../images/logos/telegram.png";
import twitterIcon from "../../images/logos/twitter.png";
import whatsappIcon from "../../images/logos/whatsapp.png";
import youtubeIcon from "../../images/logos/youtube.png";
import sms2 from "../../images/logos/sms.png";
import phones from "../../images/logos/phone.png";
import phone2 from "../../images/logos/phone3.png";
import cardLogo from "../../images/logos/card.png";
import loc from "../../images/logos/loc.png";
import web from "../../images/logos/web.png";
import mail from "../../images/logos/mail.png";
import dargahLogo from "../../images/logos/dargah.png";
import shaba from "../../images/logos/shaba.png";
import linkLogo from "../../images/logos/link.png";
import nemunekarLogo from "../../images/logos/nemunekar.png";
import resumeLogo from "../../images/logos/resume.png";
import katalogLogo from "../../images/logos/katalog.png";
import tasvirLogo from "../../images/logos/tasvir.png";
import threadsIcon from "../../images/logos/Threads.png";
import { Link, useLocation } from "react-router-dom";
import { BaseURL, Folder } from "../../Addresses";

export default function UserPage(props) {
  const location = useLocation();
  const name = location.pathname.slice(1);
  const [items, setItems] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [color, setColor] = useState("#3d3d3d");

  useEffect(() => {
    document.title = "FarazCart | addInfo";
    getInfo(name);
  }, []);

  const eventClick = (event) =>{
    if(event.type==="link"){
      window.open(event.content,"blank");
    }
    if(event.type==="copy"){
      navigator.clipboard.writeText(event.content)
      .then(() => {
        // console.log('Text copied to clipboard: ' + event.content);
      })
    }
  }

  const getInfo = () => {
    const apiUrl = `${BaseURL}${Folder}/pannel/myPage/getCardInfoUser.php`; // Replace with your API endpoint URL
    // const apiUrl = "http://localhost:8000/pannel/myPage/getCardInfoUser.php"; // Replace with your API endpoint URL
    // Perform AJAX request to send data to the API
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ domain: name }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setUserInfo({
            name_view: data.data[0].name_view,
            description: data.data[0].description,
            card_name: data.data[0].card_name,
            image_view: data.data[0].Image_view,
          });
          const {
            link,
            threads,
            katalog,
            iGap,
            tasvir,
            resume,
            nemunekar,
            telegramUrl,
            phone,
            sms,
            dargah,
            gap,
            ita,
            sorush,
            bale,
            instaUrl,
            aparat,
            phoneNumber,
            RubikaUrl,
            whatsappUrl,
            mapUrl,
            webUrl,
            facebookUrl,
            emailUrl,
            fax,
            youtube,
            linkedin,
            snapChat,
            twitter,
            skype,
            shabaNumber,
            cardNumber,
          } = data.data[0];
          const color = data.data[0].color;
          const linkItems = [
            {
              itemName: "phoneNumber",
              text: "تلفن همراه",
              icon: phone2,
              addTitle: "تغییر شماره همراه",
              value: phoneNumber,
              event:{
                type:"link",
                content:`tel:${phoneNumber}`
              }
            },
            {
              itemName: "phone",
              text: "تلفن ثابت",
              icon: phones,
              addTitle: "تغییر تلفن ثابت",
              value: phone,
              event:{
                type:"link",
                content:`tel:${phone}`
              }
            },
            {
              itemName: "sms",
              text: "پیامک",
              icon: sms2,
              addTitle: "تغییر شماره پیامک",
              value: sms,
              event:{
                type:"link",
                content:`sms:${sms}`
              }
            },
            {
              itemName: "mapUrl",
              text: "موقعیت",
              icon: loc,
              addTitle: "تغییر موقعیت مکانی",
              value: mapUrl,
              event:{
                type:"link",
                content:mapUrl
              }
            },
            {
              itemName: "webUrl",
              text: "وب سایت",
              icon: web,
              addTitle: "تغییر آدرس وب سایت",
              value: webUrl,
              event:{
                type:"link",
                content:webUrl
              }
            },
            {
              itemName: "emailUrl",
              text: "ایمیل",
              icon: mail,
              addTitle:"تغییر ایمیل",
              value: emailUrl,
              event:{
                type:"link",
                content:`mailto:${emailUrl}`
              }
            },
            {
              itemName: "whatsappUrl",
              text: "واتس آپ",
              icon: whatsappIcon,
              addTitle: "تغییر واتس آپ",
              value: whatsappUrl,
              event:{
                type:"link",
                content:`https://wa.me/${whatsappUrl}`
              }
            },
            {
              itemName: "instaUrl",
              text: "اینستاگرام",
              icon: instagramIcon,
              addTitle: "تغییر آیدی اینستاگرام",
              value: instaUrl,
              event:{
                type:"link",
                content:`https://www.instagram.com/${instaUrl}`
              }
            },
            {
              itemName: "telegramUrl",
              text: "تلگرام",
              icon: telegramIcon,
              addTitle: "تغییر آیدی تلگرام",
              value: telegramUrl,
              event:{
                type:"link",
                content:`https://t.me/${telegramUrl}`
              }
            },
            {
              itemName: "facebookUrl",
              text: "فیسبوک",
              icon: facebookIcon,
              addTitle: "تغییر آیدی فیسبوک",
              value: facebookUrl,
              event:{
                type:"link",
                content:`https://www.facebook.com/${facebookUrl}`
              }
            },
            {
              itemName: "youtube",
              text: "یوتیوب",
              icon: youtubeIcon,
              addTitle: "تغییر کانال یوتیوب",
              value: youtube,
              event:{
                type:"link",
                content:`https://www.youtube.com/@${youtube}`
              }
            },
            {
              itemName: "RubikaUrl",
              text: "روبیکا",
              icon: robikaIcon,
              addTitle: "تغییر آیدی روبیکا",
              value: RubikaUrl,
              event:{
                type:"link",
                content:`https://rubika.ir/${RubikaUrl}`
              }
            },
            {
              itemName: "linkedin",
              text: "لینکدین",
              icon: linkedinIcon,
              addTitle: "تغییر لینک لینکدین",
              value: linkedin,
              event:{
                type:"link",
                content:`http://www.linkedin.com/${linkedin}`
              }
            },
            {
              itemName: "threads",
              text: "تردز",
              icon: threadsIcon,
              addTitle: "تغییر لینک تردز",
              value: threads,
              event:{
                type:"link",
                content:`https://www.threads.net/@${threads}`
              }
            },
            {
              itemName: "twitter",
              text: "توییتر",
              icon: twitterIcon,
              addTitle: "تغییر لینک توییتر",
              value: twitter,
              event:{
                type:"link",
                content:`https://twitter.com/${twitter}`
              }
            },
            {
              itemName: "skype",
              text: "اسکایپ",
              icon: skypeIcon,
              addTitle: "تغییر آیدی اسکایپ",
              value: skype,
              event:{
                type:"link",
                content:`skype:${skype}?chat`
              }
            },
            {
              itemName: "aparat",
              text: "آپارات",
              icon: aparatIcon,
              addTitle: "تغییر لینک آپارات",
              value: aparat,
              event:{
                type:"link",
                content:`https://www.aparat.com/${aparat}`
              }
            },
            {
              itemName: "igap",
              text: "آی گپ",
              icon: iGapIcon,
              addTitle: "تغییر آیدی آی گپ",
              value: iGap,
              event:{
                type:"link",
                content:`https://profile.igap.net/${iGap}`
              }
            },
            {
              itemName: "bale",
              text: "بله",
              icon: baleIcon,
              addTitle: "تغییر آیدی بله" ,
              value: bale,
              event:{
                type:"link",
                content:`https://ble.ir/${bale}`
              }
            },
            {
              itemName: "gap",
              text: "گپ",
              icon: gapIcon,
              addTitle: "تغییر آیدی گپ",
              value: gap,
              event:{
                type:"link",
                content:`https://gap.im/${gap}`
              }
            },
            {
              itemName: "sorush",
              text: "سروش",
              icon: sorushIcon,
              addTitle:"تغییر آیدی سروش",
              value: sorush,
              event:{
                type:"link",
                content:`https://splus.ir/${sorush}`
              }
            },
            {
              itemName: "ita",
              text: "ایتا",
              icon: itaIcon,
              addTitle: "تغییر آیدی ایتا",
              value: ita,
              event:{
                type:"link",
                content:`https://eitaa.com/${ita}`
              }
            },
            {
              itemName: "cardNumber",
              text: "کارت بانکی",
              icon: cardLogo,
              addTitle: "تغییر کارت بانکی",
              value: cardNumber,
              event:{
                type:"copy",
                content:cardNumber
              }
            },
            {
              itemName: "shabaNumber",
              text: "شماره شبا",
              icon: shaba,
              addTitle:"تغییر شماره شبا",
              value: shabaNumber,
              event:{
                type:"copy",
                content:shabaNumber
              }
            },
            {
              itemName: "dargah",
              text: "درگاه پرداخت",
              icon: dargahLogo,
              addTitle:"تغییر درگاه پرداخت",
              value: dargah,
              event:{
                type:"link",
                content:dargah
              }
            },
            {
              itemName: "link",
              text: "لینک",
              icon: linkLogo,
              addTitle: "تغییر لینک",
              value:link,
              event:{
                type:"link",
                content:link
              }
            },
            {
              itemName: "katalog",
              text: "کاتالوگ",
              icon: katalogLogo,
              addTitle: "تغییر لینک کاتالوگ",
              value:katalog,
              event:{
                type:"link",
                content:katalog
              }
            },
            {
              itemName: "tasvir",
              text: "تصویر",
              icon: tasvirLogo,
              addTitle: "تغییر لینک تصویر",
              value:tasvir,
              event:{
                type:"link",
                content:tasvir
              }
            },
            {
              itemName: "resume",
              text: "رزومه",
              icon: resumeLogo,
              addTitle:"تغییر لینک رزومه",
              value:resume,
              event:{
                type:"link",
                content:resume
              }
            },
            {
              itemName: "nemunekar",
              text: "نمونه کار",
              icon: nemunekarLogo,
              addTitle: "تغییر لینک نمونه کار",
              value:nemunekar,
              event:{
                type:"link",
                content:nemunekar
              }
            },
          ];
          for (var key in linkItems) {
            if (!linkItems[key].value && linkItems[key].value !== "line") {
              delete linkItems[key];
            }
          }
          setItems(linkItems);
          setColor(color);
        } else {
          // console.log(data);
        }
      })
      .catch((error) => {
        // console.log(error);
        // setError("مشکلی در ثبت اطلاعات وجود دارد . بعدا امتحان کنید")
      });
  };

  return (
    <div className="container">
      {/* mobile frame photo */}
      <img className="phone-img" src="../../../phone-bg.png" alt="" />
      <div className="add-section">
        <div
          className={"addInfo-section"}
          style={{ backgroundColor: color }}
        >
          {/* back and preview */}
          <div className="top-icons">
          </div>
          {/* name & title  */}
          <div className="user-inputs">
            <label htmlFor="inputFile">
              <div
                className="user-profil"
                style={{
                  backgroundImage: `url(${userInfo.image_view})`,
                  backgroundSize: "cover",
                }}
              >
              </div>
            </label>
            <div className="user-name">
              <h4 style={{ color: "white" }}>{userInfo.name_view}</h4>
              <h4 style={{ color: "white" }} className="jobTitle">
                {userInfo.description}
              </h4>
            </div>
          </div>
          <div className="information-part">
            <div className="info-part">
              <ul className="info-list">
                {items.map((info) => {
                  return (
                    <li className="info-item">
                      <img className="info-logo" src={info.icon} alt="logo" />
                      <div className="info-text">
                        <h4>{info.text}</h4>
                        <p>{info.value}</p>
                      </div>
                      <SlArrowLeft
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          eventClick(info.event);
                        }}
                        className="info-edit-icon"
                      />
                    </li>
                  );
                })}
                <li className="info-item">
                  <p className="footer-text"  style={{ color: color }}>
                    تمامی حقوق برای فراز کارت محفوظ است
                  </p>
                </li>
              </ul>
            </div>
            <div className="main-line"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

import DashboardAside from "./DashboardAside";
import "../../Style/Dashboard/Dashboard.css"
import User from "../../images/DashBoard/user-edit.png"
import back7 from "../../images/DashBoard/Dashboard/back7.png"
import Ellipse19 from "../../images/DashBoard/DashboardAside/Ellipse19.png"
import { useEffect, useState } from "react";
import axios from "axios";
import { BaseURL, Folder } from "../../Addresses";
import { Link } from "react-router-dom";

const Dashboard = (props) => {
    useEffect(() => {
        document.title = "FarazCart | Dashboard"
    },[]);

    return (
        <div className="DashBoardPage">
            <img className="back7" src={back7} alt="background" />
            <img className="Ellipse19" src={Ellipse19}  alt="background" />
            <div className="successBuyShop">
                <h1>پرداخت انجام نشد ! مجددا تلاش کنید.</h1>
                <Link to="/buyCart" className="btn fail" >بازگشت به سایت</Link>
            </div>
        </div>
    )
}
export default Dashboard;
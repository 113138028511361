import User from "../../images/DashBoard/Buy/profile-circle.png"
import dollar from "../../images/DashBoard/Buy/dollar-circle.png"
import Tik from "../../images/DashBoard/Buy/Group 35858.png"
import Rec from "../../images/DashBoard/Buy/Rectangle 317.png"
import DashboardAside from "./DashboardAside";
import "../../Style/Dashboard/Userpardakht.css"
import Ellipse21_2 from "../../images/DashBoard/Buy/Ellipse21.png"
import back9_2 from "../../images/DashBoard/Buy/back9.png"
import back8_2 from "../../images/DashBoard/Buy/back8.png"
import Ellipse19 from "../../images/DashBoard/DashboardAside/Ellipse19.png"
import { useEffect } from "react";
const UserPardakht = () => {
    useEffect(() => {
        document.title = "FarazCart | pardakht"
    })
    return (
        <div className="UserpardakhtPage">
            <img className="back8_2" src={back8_2} />
            <img className="Ellipse21_2" src={Ellipse21_2} />
            <img className="Ellipse19" src={Ellipse19} />
            <div className="UserpardakhtPageContent">
                <img className="back9_2" src={back9_2} />
                <div className="levelsUserpardakhtPage">
                    <img src={User} />
                    <img src={dollar} />
                    <img src={Tik} />
                </div>
                <div>
                    <img className="banerUserpardakhtPage" src={Rec} />
                </div>
                <form className="formUserpardakhtPage" method="post" action="https://panel.aqayepardakht.ir/startpay/paybtn/7EF141AB80">
                    <div className="UserpardakhtPageText">
                        <span>مبلغ قابل پرداخت</span>
                        <span>تومان 430,000 </span>
                    </div>
                    <div className="discountCode">
                        <input type='text' className="inpDomain" name='field_0' value={localStorage.getItem('domain')} /> 
                        <input type="text" className="inpTakhfif" name="voucher_code" placeholder="اکر کد تخفیف دارید، وارد کنید" />
                    </div>
                    <button className="confirmAndPay">تایید و پرداخت</button>
                </form>                
            </div>
            <DashboardAside address={"UserBuy"} />
        </div>
    )
}
export default UserPardakht;
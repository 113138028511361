import { useEffect } from "react"
import HeroNFC from "./HeroNFC"
// import ModelHa from "./ModelHa"
const NfcYab = (props) => {

    useEffect(() => {
        document.title = "FarazCart | NFC founder"
        props.showLoginBtn(true)
    })
    
    return(
        <>
            <HeroNFC />
            {/* <ModelHa /> */}
        </>
    )
}
export default NfcYab;
import "../../Style/NFCyab/HeroNFC.css"
import logoFaraz from "../../images/NFCyab/logoFaraz.png"
import back1b from '../../images/Navbar/back1.png';
import Ellipse1 from '../../images/Navbar/Ellipse1.png';
import Ellipse2 from '../../images/Navbar/Ellipse2.png';
import NFC from  "../../Json/NfcYab.json";
import "../../Style/NFCyab/ModelHa.css"
import { useEffect, useState } from "react";
import axios from "axios";
import { BaseURL, Folder } from "../../Addresses";

const HeroNFC = () => {
    const [mobiles , setMobiles] = useState({...NFC});
    const [brands,setBrands] = useState(Object.keys(NFC))
    const [searchInput , setSearchInput] = useState("");

    useEffect(()=>{
        window.scrollTo(0, 0);
        const url = `${BaseURL}${Folder}/nfcPhones.php`;
        // const url = 'http://localhost:7000/api/nfcPhones.php';
        axios.get(url)
        .then((response)=>{
            // console.log(response);
        })

    },[])

    const filterData = (event) => {
        let models = {}
        for(let key in NFC){
            // console.log(key , mobiles[key])
            models[key] = NFC[key].map(item => {
                if(item.indexOf(event.target.value) >= 0){
                    return item
                }else{
                    return "  "
                }
            } )
        }
        setMobiles({...models});
        
        if(event.target.value==="" || !event.target.value.replace(/\s/g, '').length ){
        setMobiles({...NFC});
        }

        setSearchInput(event.target.value);
    }


    return (
        <>
        <header>
            <div className="ContentNFC">
            <img className='Ellipse1_2' src={Ellipse1} alt="Ellipse1" />
            <img className='Ellipse2_2' src={Ellipse2} alt="Ellipse2" />
            <img className='back1b' src={back1b} alt="back1" />
                <img className="logoFaraz" src={logoFaraz} alt="logoFaraz" />
                <h1 className="titleNTC">
                    NFC یاب
                </h1>
                <p className="DescriptionNFC1">
                    NFC موبایل های دارای فناوری
                </p>
                <p className="DescriptionNFC2">
                    Mobile Phones That Support <span className="NFCblue"> NFC </span>  Technology
                </p>
                <p className="DescriptionNFC3">
                    معمولا اکثر تلفن های همراهی که در حال حاضر در بازار موجود هستند از فناوری <span className="NFCblue"> NFC </span> پشتیبانی میکنن اما برای اطمینان ما یه لیستی رو ازشون براتون تهیه کردیم که توی این صفحه در اختیارتون قرار گرفته
                </p>
                <input className="inputNFC" value={searchInput} onChange={filterData} type="text" placeholder="برای جست و جوی مدل مورد نظرتونو وارد کنید" />
            </div>
        </header>
        <section className="model_section">
            <table  className="tableNFC">
                <thead>
                    <tr className="background">
                        {brands.map((brand, index) => (
                            <th className="titleNFC" key={index}>{brand}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr className="background">
                        {brands.map((brand, index) => (
                            <td key={index}>
                                {mobiles[brand].map((value, idx) => (
                                    <p className="DescriptionModel width" key={idx}>{value}</p>
                                ))}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </section>
        </>

    )
}
export default HeroNFC;
import { Routes, Route, Link } from "react-router-dom";
import "../../Style/Navbar.css";
import { useRef, useState } from "react";
import farazNavLogo from "../../images/Navbar/farazNavLogo.png";
import menuIcon from "../../images/Navbar/menuIcon.png";
import menuIconOpen from "../../images/Navbar/open.png";
import menuIconClose from "../../images/Navbar/close.png";
import { useEffect } from "react";
import AllRoutes from "../../routers/AllRoutes";



const Navabar = () => {
  const [login, setLogin] = useState(true);
  const [title, setTitle] = useState(true);
  const [navStatus, setNavStatus] = useState(true);
  const [menu, SetMenu] = useState(false);
  const [hambMenu, setHambMenu] = useState(true);
  const [showLoginBtn , setShowLoginBtn] = useState(true);
  const DashDocName = [
    "FarazCart | Dashboard",
    "FarazCart | Editing",
    "FarazCart | Buy",
    "FarazCart | Cart",
    "FarazCart | Info",
    "FarazCart | pardakht",
    "FarazCart | addInfo",
  ];

  useEffect(() => {
    setTitle(document.title)
    if (DashDocName.find(item => item=== document.title)) {
      setLogin(false);
      // console.log("false");
    }else{
      setLogin(true);
    }

    if (window.innerWidth <= 768) {
      DashDocName.forEach((item) => {
        if (document.title === item) {
          setHambMenu(false);
        }
        lowerSize();
      });
    } else {
      overSize();
      setHambMenu(true);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      // Remove resize event listener when component unmounts
      window.removeEventListener("resize", handleResize);
    };

  }, [menu, login]);

  const lowerSize = () => {
    setNavStatus(false);
    SetMenu(false);
  };

  const overSize = () => {
    setNavStatus(true);
    SetMenu(true);
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      lowerSize();
    } else {
      overSize();
    }
  };

  const HamburgerMenu = () => {
    setNavStatus((prev)=>!prev);
  };

  const linksIntop = () => {
    if (window.innerWidth <= 768) {
      setNavStatus(false);
    }
  };

  return (
    <main>
      {login ? (
        <nav className="navbar">
          <div className="div_vurud_button" style={{visibility:showLoginBtn?"visible":"hidden"}}>
            { showLoginBtn ?
              <Link
              className="button_vurud"
              to="/Login"
              >
                ورود
              </Link>
               : "" }
          </div>
          <img className="farazNavLogo" src={farazNavLogo} alt="farazNavLogo" />
          {hambMenu ? (
            <img
              className="menuIcon"
              src={ navStatus ? menuIconOpen : menuIconClose}
              onClick={HamburgerMenu}
              alt="menuIcon"
            />
          ) : null}
          <div className="links" style={{ display: navStatus ? "flex" : "none" }} >
            <div className="link">
              <Link to="/" onClick={linksIntop}>
                صفحه نخست
              </Link>
            </div>
            <div className="link">
              <Link to="/AboutUs" onClick={linksIntop}>
                مُعرفی
              </Link>
            </div>
            <div className="link">
              <Link to="/NfcYab" onClick={linksIntop}>
                NFC یاب
              </Link>
            </div>
            <div className="link" style={{zIndex:100}}>
              <Link to="/AloFarazCart" onClick={linksIntop}>
                الو فراز کارت؟
              </Link>
            </div>
          </div>
        </nav>
      ) : null}
      <AllRoutes showLoginBtn={setShowLoginBtn} showNavbar={setLogin}/>
      {/* {routers} */}
    </main>
  );
};
export default Navabar;

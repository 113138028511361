import farazCartPhoto from "../.././images/DashBoard/faraz.png";
import bag from "../.././images/DashBoard/bag-02.png";
import chart from "../.././images/DashBoard/chart-square.png";
import user from "../.././images/DashBoard/user-edit.png";
import wallet from "../.././images/DashBoard/wallet.png";
import logout from "../.././images/DashBoard/logout.png";
import menuIcon from "../../images/Navbar/menuIcon.png"
import Ellipse20 from "../.././images/DashBoard/DashboardAside/Ellipse20.png";
import home_2 from "../.././images/DashBoard/DashboardAside/home-2.png";
import "../../images/DashBoard/DashboardAside/fontawesome-free-6.4.0-web/css/all.css"
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "../../Style/Dashboard/DashboardAside.css"




const DashboardAside = (props) => {
    const [login, setLogin] = useState(true);
    const [dashStatus, setDashStatus] = useState(true);
    const [safeAsli , SetSafeAsli] = useState(false);
    

    useEffect(() => {
        // Add event listener for resize event
        window.addEventListener('resize', handleResize);

        return () => {
            // Remove resize event listener when component unmounts
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setDashStatus(false);
            SetSafeAsli(true)
        } else {
            setDashStatus(true);
            SetSafeAsli(false)
        }
    }

    const displayDashboardAside = () => {
        SetSafeAsli(true);
        if (dashStatus) {
            setDashStatus(false);
        } else {
            setDashStatus(true);
        }
    }
    return (
        <>
            {/* <img className='menuIconInDashboard' src={menuIcon} alt="menuIcon" onClick={displayDashboardAside} /> */}
            <i className="menuIconInDashboard fa-solid fa-bars" onClick={displayDashboardAside}></i>
            <div className="Dashboard" style={{ display: dashStatus ? 'block' : 'none' }}>
                <aside className='DashboardNavbar'>
                    <img className="Ellipse20" src={Ellipse20} alt="background" />
                    <img className="farazCartPhotoDashboard" src={farazCartPhoto} alt="background"/>
                    <div className='linksDashboard' >
                        <div className='linkDashboard linkDashboardEdit LinkDashToHome' style={{ display:'none' }}>
                            {/* <Link to="/" alt="edit">صفحه اصلی</Link> */}
                            {/* <i class="fa-solid fa-house-user"></i> */}
                        </div>
                        <div className={`linkDashboard ${props.address === "Dashboard" ? "linkDashboardEdit" : "" }`}>
                            <Link to="/Dashboard" alt="edit">ویرایش پروفایل</Link>
                            <i class="fa-solid fa-user-pen"></i>
                        </div>
                        <div className={`linkDashboard ${props.address === "UserBuy" ? "linkDashboardEdit" : props.address === "EditCart" ? "linkDashboardEdit" : ""}`}>
                            <Link to="/buyCart" alt="buy">خرید کارت</Link>
                            <i class="fa-solid fa-bag-shopping"></i>
                        </div>
                        <div className={`linkDashboard ${props.address === "UserCart" ? "linkDashboardEdit" : "" }`}>
                            <Link to="/MyCarts" alt="wallet">کارت های من</Link>
                            <i class="fa-solid fa-wallet"></i>
                        </div>
                        {/* <div className={`linkDashboard ${props.address === "UserInfo" ? "linkDashboardEdit" : "" }`}>
                            <Link to="/AllInfo" >اطلاعات کلی</Link>
                            <i class="fa-solid fa-border-all"></i>
                        </div> */}
                        {/* <div className='linkDashboard'>
                            <Link to="/MyPage" >ثبت اطلاعات</Link>
                            <i class="fa-solid fa-border-all"></i>
                        </div> */}
                    </div>
                    <div className="linkDashboard logoutDiv">
                        <Link to="/" >خروج از حساب کاربری</Link>
                        <i class="fa-solid fa-arrow-right-from-bracket"></i>
                    </div>
                </aside >
            </div >
            <div>
            </div>
        </>

    )
}
export default DashboardAside;
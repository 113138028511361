import { useEffect } from "react"
import MainAlo from "./MainAlo"
const AloFarazCart = (props) => {

    useEffect(() => {
        document.title = "FarazCart | Communication";
        window.scrollTo(0, 0);
        props.showLoginBtn(true)
    })

    return(
        <>
            <MainAlo />
        </>
    )
}
export default AloFarazCart;
import React, { useEffect, useState } from "react";
import "./MyPage.css";
import { SketchPicker, BlockPicker } from "react-color";
import MenuPage from "../MenuPage/MenuPage";
import SettingPage from "../MenuPage/SettingPage";
import { AiOutlineSetting } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiOutlineCamera } from "react-icons/ai";
import { RiEdit2Fill } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
//logos
import aparatIcon from "../../images/logos/aparat.png";
import baleIcon from "../../images/logos/bale.png";
import facebookIcon from "../../images/logos/facebook.png";
import gapIcon from "../../images/logos/gap.png";
import instagramIcon from "../../images/logos/instagram.png";
import itaIcon from "../../images/logos/ita.png";
import linkedinIcon from "../../images/logos/linkedin.png";
import robikaIcon from "../../images/logos/robika.png";
import skypeIcon from "../../images/logos/skype.png";
import sorushIcon from "../../images/logos/sorush.png";
import telegramIcon from "../../images/logos/telegram.png";
import twitterIcon from "../../images/logos/twitter.png";
import whatsappIcon from "../../images/logos/whatsapp.png";
import youtubeIcon from "../../images/logos/youtube.png";
import sms2 from "../../images/logos/sms.png";
import phones from "../../images/logos/phone.png";
import phone2 from "../../images/logos/phone3.png";
import cardLogo from "../../images/logos/card.png";
import loc from "../../images/logos/loc.png";
import web from "../../images/logos/web.png";
import mail from "../../images/logos/mail.png";
import dargahLogo from "../../images/logos/dargah.png";
import shaba from "../../images/logos/shaba.png";
import linkLogo from "../../images/logos/link.png";
import nemunekarLogo from "../../images/logos/nemunekar.png";
import resumeLogo from "../../images/logos/resume.png";
import katalogLogo from "../../images/logos/katalog.png";
import tasvirLogo from "../../images/logos/tasvir.png";
import threadsIcon from "../../images/logos/Threads.png";
import iGapIcon from "../../images/logos/aigap.png";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { BaseURL, Folder } from "../../Addresses";

const menuItems = [
  { itemName: "line", text: "تماس و راه های ارتباطی", icon: "line", addTitle: "line" },
  {
    itemName: "phoneNumber",
    text: "تلفن همراه",
    icon: phone2,
    addTitle: "افزودن شماره همراه",
  },
  { itemName: "phone", text: "تلفن ثابت", icon: phones, addTitle: "افزودن تلفن ثابت" },
  { itemName: "sms", text: "پیامک", icon: sms2, addTitle: "افزودن شماره پیامک" },
  { itemName: "mapUrl", text: "موقعیت", icon: loc, addTitle: "افزودن موقعیت مکانی" },
  { itemName: "emailUrl", text: "ایمیل", icon: mail, addTitle: "افزودن ایمیل" },
  { itemName: "webUrl", text: "وب سایت", icon: web, addTitle: "افزودن آدرس وب سایت" },
  { itemName: "line", text: "شبکه های اجتماعی", icon: "line", addTitle: "line" },
  {
    itemName: "whatsappUrl",
    text: "واتس آپ",
    icon: whatsappIcon,
    addTitle: "افزودن واتس آپ",
  },
  {
    itemName: "instaUrl",
    text: "اینستاگرام",
    icon: instagramIcon,
    addTitle: "افزودن آیدی اینستاگرام",
  },
  {
    itemName: "telegramUrl",
    text: "تلگرام",
    icon: telegramIcon,
    addTitle: "افزودن آیدی تلگرام",
  },
  {
    itemName: "facebookUrl",
    text: "فیسبوک",
    icon: facebookIcon,
    addTitle: "افزودن آیدی فیسبوک",
  },
  {
    itemName: "youtube",
    text: "یوتیوب",
    icon: youtubeIcon,
    addTitle: "افزودن کانال یوتیوب",
  },
  {
    itemName: "RubikaUrl",
    text: "روبیکا",
    icon: robikaIcon,
    addTitle: "افزودن آیدی روبیکا",
  },
  {
    itemName: "linkedin",
    text: "لینکدین",
    icon: linkedinIcon,
    addTitle: "افزودن لینکدین",
  },
  {
    itemName: "threads",
    text: "تردز",
    icon: threadsIcon,
    addTitle: "افزودن لینک تردز",
  },
  {
    itemName: "twitter",
    text: "توییتر",
    icon: twitterIcon,
    addTitle: "افزودن توییتر",
  },
  { itemName: "skype", text: "اسکایپ", icon: skypeIcon, addTitle: "افزودن آیدی اسکایپ" },
  {
    itemName: "igap",
    text: "آی گپ",
    icon: iGapIcon,
    addTitle: "افزودن آیدی آی گپ",
  },
  { itemName: "aparat", text: "آپارات", icon: aparatIcon, addTitle: "افزودن لینک آپارات" },
  { itemName: "bale", text: "بله", icon: baleIcon, addTitle: "افزودن آیدی بله" },
  { itemName: "gap", text: "گپ", icon: gapIcon, addTitle: "افزودن آیدی گپ" },
  { itemName: "sorush", text: "سروش", icon: sorushIcon, addTitle: "افزودن آیدی سروش" },
  { itemName: "ita", text: "ایتا", icon: itaIcon, addTitle: "افزودن آیدی ایتا" },
  { itemName: "line", text: "اطلاعات و پرداخت", icon: "line", addTitle: "line" },
  {
    itemName: "shabaNumber",
    text: "شماره شبا",
    icon: shaba,
    addTitle: "افزودن شماره شبا",
  },
  {
    itemName: "cardNumber",
    text: "کارت بانکی",
    icon: cardLogo,
    addTitle: "افزودن کارت بانکی",
  },
  {
    itemName: "dargah",
    text: "درگاه پرداخت",
    icon: dargahLogo,
    addTitle: "افزودن درگاه پرداخت",
  },
  {
    itemName: "link",
    text: "لینک",
    icon: linkLogo,
    addTitle: "افزودن لینک",
  },
  {
    itemName: "katalog",
    text: "کاتالوگ",
    icon: katalogLogo,
    addTitle: "افزودن لینک کاتالوگ",
  },
  {
    itemName: "tasvir",
    text: "تصویر",
    icon: tasvirLogo,
    addTitle: "افزودن لینک تصویر",
  },
  {
    itemName: "resume",
    text: "رزومه",
    icon: resumeLogo,
    addTitle: "افزودن لینک رزومه",
  },
  {
    itemName: "nemunekar",
    text: "نمونه کار",
    icon: nemunekarLogo,
    addTitle: "افزودن لینک نمونه کار",
  },
];

export default function MyPage(props) {
  const location = useLocation();
  const name = location.pathname.slice(10);
  const [menuIsShow, setMenuIsShow] = useState(false);
  const [editModalIsShow, setEditModalIsShow] = useState(false);
  const [addModalIsShow, setAddModalIsShow] = useState(false);
  const [userName, setUserName] = useState("");
  const [userDesc, setUserDesc] = useState("");
  const [selectItem, setSelectItem] = useState([]);
  const [settingShow, setSettingShow] = useState(false);
  const [items, setItems] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [color, setColor] = useState("#3d3d3d");
  const [blockPickerColor, setBlockPickerColor] = useState("#37d67a");
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "FarazCart | addInfo";
    getInfo(name);
    aceess();
  }, []);

  const getInfo = () => {
    const apiUrl = `${BaseURL}${Folder}/pannel/myPage/getCardInfo.php`; // Replace with your API endpoint URL
    // const apiUrl = "http://localhost:8000/pannel/myPage/getCardInfo.php"; // Replace with your API endpoint URL
    const jwtToken = JSON.parse(localStorage.getItem("login")).acess; // Replace with your JWT token
    // Perform AJAX request to send data to the API
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ domain: name }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setUserInfo({
            name_view: data.data[0].name_view,
            description: data.data[0].description,
            card_name: data.data[0].card_name,
            image_view: data.data[0].Image_view,
          });
          const {
            link,
            iGap,
            katalog,
            tasvir,
            resume,
            nemunekar,
            telegramUrl,
            phone,
            sms,
            dargah,
            gap,
            ita,
            sorush,
            bale,
            instaUrl,
            aparat,
            phoneNumber,
            RubikaUrl,
            whatsappUrl,
            mapUrl,
            webUrl,
            facebookUrl,
            emailUrl,
            fax,
            youtube,
            threads,
            linkedin,
            snapChat,
            twitter,
            skype,
            shabaNumber,
            cardNumber,
          } = data.data[0];
          const color = data.data[0].color;
          const linkItems = [
            {
              itemName: "phoneNumber",
              text: "تلفن همراه",
              icon: phone2,
              addTitle: "تغییر شماره همراه",
              value: phoneNumber,
            },
            {
              itemName: "phone",
              text: "تلفن ثابت",
              icon: phones,
              addTitle: "تغییر تلفن ثابت",
              value: phone,
            },
            {
              itemName: "sms",
              text: "پیامک",
              icon: sms2,
              addTitle: "تغییر شماره پیامک",
              value: sms,
            },
            {
              itemName: "mapUrl",
              text: "موقعیت",
              icon: loc,
              addTitle: "تغییر موقعیت مکانی",
              value: mapUrl,
            },
            {
              itemName: "webUrl",
              text: "وب سایت",
              icon: web,
              addTitle: "تغییر آدرس وب سایت",
              value: webUrl,
            },
            {
              itemName: "emailUrl",
              text: "ایمیل",
              icon: mail,
              addTitle:"تغییر ایمیل",
              value: emailUrl,
            },
            {
              itemName: "whatsappUrl",
              text: "واتس آپ",
              icon: whatsappIcon,
              addTitle: "تغییر واتس آپ",
              value: whatsappUrl,
            },
            {
              itemName: "instaUrl",
              text: "اینستاگرام",
              icon: instagramIcon,
              addTitle: "تغییر آیدی اینستاگرام",
              value: instaUrl,
            },
            {
              itemName: "telegramUrl",
              text: "تلگرام",
              icon: telegramIcon,
              addTitle: "تغییر آیدی تلگرام",
              value: telegramUrl,
            },
            {
              itemName: "facebookUrl",
              text: "فیسبوک",
              icon: facebookIcon,
              addTitle: "تغییر آیدی فیسبوک",
              value: facebookUrl,
            },
            {
              itemName: "youtube",
              text: "یوتیوب",
              icon: youtubeIcon,
              addTitle: "تغییر کانال یوتیوب",
              value: youtube,
            },
            {
              itemName: "RubikaUrl",
              text: "روبیکا",
              icon: robikaIcon,
              addTitle: "تغییر آیدی روبیکا",
              value: RubikaUrl,
            },
            {
              itemName: "linkedin",
              text: "لینکدین",
              icon: linkedinIcon,
              addTitle: "تغییر لینک لینکدین",
              value: linkedin,
            },
            {
              itemName: "threads",
              text: "تردز",
              icon: threadsIcon,
              addTitle: "تغییر لینک تردز",
              value: threads,
            },
            {
              itemName: "twitter",
              text: "توییتر",
              icon: twitterIcon,
              addTitle: "تغییر لینک توییتر",
              value: twitter,
            },
            {
              itemName: "skype",
              text: "اسکایپ",
              icon: skypeIcon,
              addTitle: "تغییر آیدی اسکایپ",
              value: skype,
            },
            {
              itemName: "aparat",
              text: "آپارات",
              icon: aparatIcon,
              addTitle: "تغییر لینک آپارات",
              value: aparat,
            },
            {
              itemName: "bale",
              text: "بله",
              icon: baleIcon,
              addTitle: "تغییر آیدی بله" ,
              value: bale,
            },
            {
              itemName: "gap",
              text: "گپ",
              icon: gapIcon,
              addTitle: "تغییر آیدی گپ",
              value: gap,
            },
            {
              itemName: "sorush",
              text: "سروش",
              icon: sorushIcon,
              addTitle:"تغییر آیدی سروش",
              value: sorush,
            },
            {
              itemName: "ita",
              text: "ایتا",
              icon: itaIcon,
              addTitle: "تغییر آیدی ایتا",
              value: ita,
            },
            {
              itemName: "igap",
              text: "آی گپ",
              icon: iGapIcon,
              addTitle: "تغییر آیدی آی گپ",
              value: iGap,
            },
            {
              itemName: "cardNumber",
              text: "کارت بانکی",
              icon: cardLogo,
              addTitle: "تغییر کارت بانکی",
              value: cardNumber,
            },
            {
              itemName: "shabaNumber",
              text: "شماره شبا",
              icon: shaba,
              addTitle:"تغییر شماره شبا",
              value: shabaNumber,
            },
            {
              itemName: "dargah",
              text: "درگاه پرداخت",
              icon: dargahLogo,
              addTitle:"تغییر درگاه پرداخت",
              value: dargah,
            },
            {
              itemName: "link",
              text: "لینک",
              icon: linkLogo,
              addTitle: "تغییر لینک",
              value:link
            },
            {
              itemName: "katalog",
              text: "کاتالوگ",
              icon: katalogLogo,
              addTitle: "تغییر لینک کاتالوگ",
              value:katalog
            },
            {
              itemName: "tasvir",
              text: "تصویر",
              icon: tasvirLogo,
              addTitle: "تغییر لینک تصویر",
              value:tasvir

            },
            {
              itemName: "resume",
              text: "رزومه",
              icon: resumeLogo,
              addTitle:"تغییر لینک رزومه",
              value:resume

            },
            {
              itemName: "nemunekar",
              text: "نمونه کار",
              icon: nemunekarLogo,
              addTitle: "تغییر لینک نمونه کار",
              value:nemunekar
            },
          ];
          for (var key in linkItems) {
            if (!linkItems[key].value && linkItems[key].value !== "line") {
              delete linkItems[key];
            }
          }
          setItems(linkItems);
          setColor(color);
        } else {
          // console.log(data);
        }
      })
      .catch((error) => {
        // console.log(error);
        // setError("مشکلی در ثبت اطلاعات وجود دارد . بعدا امتحان کنید")
      });
  };

  const aceess =() => {
    const getDataUrl = `${BaseURL}${Folder}/pannel/myPage/getCardInfoAccess.php`; // Replace with your API endpoint URL
    // const getDataUrl = 'http://localhost:8000/pannel/myPage/getCardInfoAccess.php'; // Replace with your API endpoint URL
    const jwtToken = JSON.parse(localStorage.getItem('login')).acess; // Replace with your JWT token
    fetch(getDataUrl, {
        method: 'POST',
        headers: {
            "Authorization": jwtToken,
        },
        body: JSON.stringify({
          name: name,
        }),
    })
    .then(response => response.json())
    .then(data => {      
      if(data !== "suceess"){
        // console.log(data);
        navigate('/');
      }

    })
    .catch(error => {
      // console.log("err",error);
    });
  }

  const submitUsername = () => {
    // const apiUrl ="http://localhost:8000/pannel/myPage/changeUsernameAndDesc.php"; // Replace with your API endpoint URL
    const apiUrl = `${BaseURL}${Folder}/pannel/myPage/changeUsernameAndDesc.php`; // Replace with your API endpoint URL
    const jwtToken = JSON.parse(localStorage.getItem("login")).acess; // Replace with your JWT token
    // Perform AJAX request to send data to the API


    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        domain: name,
        name: userName,
        description: userDesc,
      }),
    })
      .then((response) => response.text())
      .then((data) => {
        // console.log(data);
        getInfo();
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const uploadProfileImg = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    formData.append("user", userInfo.card_name);
    const apiUrl = `${BaseURL}${Folder}/pannel/myPage/uploadProfileImage.php`; // Replace with your API endpoint URL
    // const apiUrl = "http://localhost:8000/pannel/myPage/uploadProfileImage.php"; // Replace with your API endpoint URL
    const jwtToken = JSON.parse(localStorage.getItem("login")).acess; // Replace with your JWT token
    const headers = new Headers();
    headers.append("Authorization", jwtToken);

    try {
      const reponse = await fetch(apiUrl, {
        method: "POST",
        body: formData,
        headers: headers,
      });
      const data = await reponse.text();
      // console.log(data);
      getInfo();
    } catch (eror) {
      // console.log("er", eror);
    }

    // const header = {
    //   'Content-Type': 'multipart/form-data',
    //   Authorization: jwtToken
    // }
    // axios.post("http://localhost:8000/pannel/myPage/uploadProfileImage.php",formData).then(res=>{
    //   console.log(res);
    // }).catch(err=>{
    //   console.log(err);
    // })
    // const reader = new FileReader();
    // console.log("reader");

    // reader.onloadend = () => {
    // console.log("send");
    // const base64Data = reader.result.split(',')[1];
    // Perform AJAX request to send data to the API
    // fetch(apiUrl, {
    //   method: "POST",
    //   // headers: {
    //     // Authorization: jwtToken,
    //     // "Content-Type": "application/json",
    //   // },
    //   body: formData,
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    // });

    // console.log("end");
    // reader.readAsDataURL(file);
    // }
  };

  return (
    <div className="container">
      {/* mobile frame photo */}
      <img className="phone-img" src="../../../phone-bg.png" alt="" />
      <div className="add-section">
        <div
          className={
            menuIsShow || editModalIsShow
              ? "addInfo-section add-filter"
              : "addInfo-section"
          }
          style={{ backgroundColor: color }}
        >
          {/* back and preview */}
          <div className="top-icons">
            <Link to={"/MyCarts"} className="back-icon">
              <IoIosArrowBack />
              بازگشت
            </Link>
            <a href={`/${name}`} target="_blank" className="back-icon" rel="noreferrer">
              پیش نمایش
              <IoIosArrowForward />
            </a>
          </div>

          {/* name & title  */}
          <div className="user-inputs">
            <label htmlFor="inputFile">
              <div
                className="user-profil"
                style={{
                  backgroundImage: `url(${userInfo.image_view})`,
                  backgroundSize: "cover",
                }}
              >
                <span className="camera-icon">
                  <AiOutlineCamera />
                </span>
                {/* <img src="./user.jpg" alt="" /> */}
              </div>
            </label>
            <input
              id="inputFile"
              accept="image/*"
              type="file"
              name="file"
              onChange={(event) => {
                uploadProfileImg(event);
              }}
            />
            <div className="user-name">
              <span
                onClick={() => setEditModalIsShow(true)}
                className="edit-icon"
              >
                <RiEdit2Fill />
              </span>
              <h4 style={{ color: "white" }}>{userInfo.name_view}</h4>
              <h4 style={{ color: "white" }} className="jobTitle">
                {userInfo.description}
              </h4>
            </div>
          </div>
          <div className="information-part">
            <div className="info-part">
              <ul className="info-list">
                {/* item haye zakhire shode  */}
                {items.map((info) => {
                  return (
                    <li className="info-item">
                      <img className="info-logo" src={info.icon} alt="logo" />
                      <div className="info-text">
                        <h4>{info.text}</h4>
                        <p>{info.value}</p>
                      </div>
                      <RiEdit2Fill
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectItem(info);
                          setAddModalIsShow(true);
                        }}
                        className="info-edit-icon"
                      />
                    </li>
                  );
                })}
                {/* new item show  */}
                <li className="info-item">
                  <button className="add-btn" onClick={() => setMenuIsShow(true)}>
                    افزودن
                    <AiOutlinePlus className="plus-icon" />
                  </button>
                </li>
              </ul>
            </div>
            <div className="main-line"></div>
          </div>

          {/* setting show  */}
          <span
            className="setting-icon"
            onClick={() => setSettingShow(!settingShow)}
            style={{ backgroundColor: color }}
          >
            <AiOutlineSetting />
          </span>
        </div>

        {/* edit link items  */}
        <div className={menuIsShow ? "active edit-box" : "edit-box"}>
          <div
            onClick={() => setMenuIsShow(false)}
            className="close-part"
          ></div>
          <div className="menu-part">
            <div className="line"></div>
            <h4 className="addMenuTitle">افزودن لینک جدید به کارت</h4>
            <div className="menu-items" dir="rtl">
              {menuItems.map((item) => {
                return item.itemName !== "line" ? (
                  <div
                    key={item.id}
                    className="menu-item"
                    onClick={() => {
                      setSelectItem(item);
                      setAddModalIsShow(true);
                    }}
                  >
                    <img src={item.icon} alt="logo" className="info-logo" />
                    <p className="addMenuTitle">{item.text}</p>
                  </div>
                ) : (
                  <div className="menu-title">
                    {item.text}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* new link - list items  */}
        <div className={addModalIsShow ? "active edit-box" : "edit-box"}>
          <div
            onClick={() => setAddModalIsShow(false)}
            className="close-part"
          ></div>
          <div className="menu-part">
            <div className="line"></div>
            {/* <h4>افزودن لینک جدید به کارت</h4> */}
            {addModalIsShow && (
              <MenuPage
                color={color}
                selectItem={selectItem}
                getitems={getInfo}
                userInformation={userInfo}
              />
            )}
          </div>
        </div>

        {/* edit user infos*/}
        <div className={editModalIsShow ? "active edit-box" : "edit-box"}>
          <div
            onClick={() => setEditModalIsShow(false)}
            className="close-part"
          ></div>
          <div className="menu-part">
            <div className="line"></div>
            <h4>ویرایش مشخصات کارت</h4>
            <div className="edit-items" dir="rtl">




              <div className="edit-input">
                <CiEdit className="edit-input-icon" />
                <p className="edit-title">نام :</p>
                <input
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="edit-input">
                <CiEdit className="edit-input-icon" />
                <p className="edit-title">توضیحات : </p>
                <input
                  className="inp2"
                  type="text"
                  value={userDesc}
                  onChange={(e) => setUserDesc(e.target.value)}
                ></input>
              </div>





              
            </div>
            <button onClick={submitUsername} className="add-edit" style={{ backgroundColor: color}} >
              تایید و ذخیره اطلاعات
            </button>
          </div>
        </div>

        {/* setting  */}
        <div className={settingShow ? "active edit-box" : "edit-box"}>
          <div
            onClick={() => setSettingShow(false)}
            className="close-part"
          ></div>
          <div className="menu-part">
            <div className="line"></div>
            <h4>تنظیمات</h4>
            {settingShow && (
              <SettingPage color={color} userInformation={userInfo} getitems={getInfo} />
            )}
            {/* <button className="add-edit">تایید و ذخیره اطلاعات</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

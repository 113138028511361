import "../../../Style/Sabtenam/Login.css";
import Ellipse16 from '../../../images/Sabtenam/Login/Ellipse16.png';
import Ellipse17 from '../../../images/Sabtenam/Login/Ellipse17.png';
import Ellipse18 from '../../../images/Sabtenam/Login/Ellipse18.png';
import back5 from '../../../images/Sabtenam/Login/back5.png';
import back6 from '../../../images/Sabtenam/Login/back6.png';
import logoFarazLog from '../../../images/Sabtenam/Login/logoFaraz.png';
import farazCartLogin from '../../../images/Sabtenam/Login/farazCart.png';
import { useEffect, useState } from "react";
import axios from "axios";
import { Route, json, useLocation, useNavigate } from "react-router-dom";
import { BaseURL,Folder } from "../../../Addresses";

function Payam(props) {
    const [code, setCode] = useState('');
    const location = useLocation();
    const [login , setLogin] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');

    useEffect(() => {
        props.showLoginBtn(false);
        setLogin(JSON.parse(localStorage.getItem('login')));
    },[]);

    const handleChange = (e) => {
        const {value , maxLength} = e.target;
        const code = value.slice(0,maxLength);
        setCode(code);
    }

    const handleSubmit = (e) => {
        e.preventDefault(); // prevent default form submission behavior
        const dataToSend = {
            code ,
            smsToken: login.sms,
            accessToken:login.acess
        };

        const url = `${BaseURL}${Folder}/Login/Fetchdata.php`;
        // 'http://localhost:8000/Login/Fetchdata.php'
        // Make an HTTP POST request to the PHP script
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)
        })
            .then(response => response.json())
            .then(responseData => {
                // Process the response data from the PHP script if needed
                // console.log(responseData);
                if(responseData.userData){
                    navigate('/Dashboard')
                    props.setAuth(true);
                    localStorage.setItem("auth",'AWXVLF!#@TGDD6324tSFVSC!@$^&*(');
                }else{
                    // console.log(responseData);
                    setError("کد تایید نامعتبر است.");
                    props.setAuth(false);
                    localStorage.setItem("auth",'');
                }
            })
            .catch(error => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });

    };

    return (
        <main className="loginContent">
            <section className="LoginDiv">
                <p className="OwnershipSite"> تمام حقوق برای فراز محفوظ است </p>
                <img className='logoFarazLog' src={logoFarazLog} alt="logoFarazLog" />
                <img className='Ellipse16' src={Ellipse16} alt="Ellipse16" />
                <img className='Ellipse17' src={Ellipse17} alt="Ellipse17" />
                <img className='Ellipse18' src={Ellipse18} alt="Ellipse18" />
                <img className='back5' src={back5} alt="back5" />
                <img className='back6' src={back6} alt="back6" />
                <div className="backWhiteRispan">
                    <img className='farazCartLogin' src={farazCartLogin} alt="farazCartLogin" />
                    <div className="LoginBox">
                        <img className='Ellipse18_2' src={Ellipse18} alt="Ellipse18_2" />
                        <h3>   به <span>  فرازکارت</span> خوش آمدید </h3>
                        <form onSubmit={handleSubmit}>
                            <div className="userPass">
                                <label> کد تایید ارسال شده را وارد نمایید. </label>
                                <div className="divInputCode">
                                    <input className="codeNumber" type="number" maxLength="5" value={code} onChange={handleChange} name="code" minLength="1" maxLength="5" required />
                                    <div className="inputDiv inputDiv1"></div>
                                    <div className="inputDiv inputDiv2"></div>
                                    <div className="inputDiv inputDiv3"></div>
                                    <div className="inputDiv inputDiv4"></div>
                                    <div className="inputDiv inputDiv5"></div>
                                </div>
                            </div>
                            <div>
                                <button className="RegisterLoginClick codeNumberSend" type="submit" name="submit"> ثبت کد تایید  </button>
                            </div>
                            <p className="mainError">{error.length ? error : ""}</p>
                        </form>
                    </div>
                </div>
            </section >
        </main>
    );
}
export default Payam;









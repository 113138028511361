import { useEffect } from "react"
import MainAlo from "../Alo/MainAlo"
import MainAbout from "./MainAbout"
const AboutUs = (props) => {

    useEffect(() => {
        document.title = "FarazCart | Communication"
        props.showLoginBtn(true)
    })

    return(
        <>
            <MainAbout />
        </>
    )
}
export default AboutUs;
import DashboardAside from "./DashboardAside";
import "../../Style/Dashboard/Dashboard.css"
import User from "../../images/DashBoard/user-edit.png"
import back7 from "../../images/DashBoard/Dashboard/back7.png"
import Ellipse19 from "../../images/DashBoard/DashboardAside/Ellipse19.png"
import { useEffect, useState } from "react";
import axios from "axios";
import { BaseURL, Folder } from "../../Addresses";
import { Link } from "react-router-dom";

const Dashboard = (props) => {
    useEffect(() => {
        document.title = "FarazCart | Dashboard"
        //save card buyes
        fetchData();
    },[]);


    const fetchData =() => {
        const getDataUrl = `${BaseURL}${Folder}/pannel/KharidCart/callBack.php`;
        // const getDataUrl = 'http://localhost:8000/pannel/Virayesh/GetData.php'; // Replace with your API endpoint URL
        const jwtToken = JSON.parse(localStorage.getItem('login')).acess; // Replace with your JWT token
        const domain = localStorage.getItem('domain'); // Replace with your JWT token
        fetch(getDataUrl, {
            method: 'POST',
            headers: {
                "Authorization": jwtToken,
            },
            body:JSON.stringify({domain})
        })
        .then(response => response.text())
        .then(data => {
            //
        })
        .catch(error => {
            //
        });
    }

    return (
        <div className="DashBoardPage">
            <img className="back7" src={back7} alt="background" />
            <img className="Ellipse19" src={Ellipse19}  alt="background" />
            <div className="successBuyShop">
                <h1 className="success">پرداخت با موفقیت انجام شد</h1>
                <Link to="/MyCarts" className="btn success" >بازگشت به سایت</Link>
            </div>
        </div>
    )
}
export default Dashboard;
import React, { useEffect, useState } from 'react';
import { SketchPicker , BlockPicker } from "react-color";
import { BaseURL, Folder } from '../../Addresses';



export default function SettingPage(props) {
  const [sketchPickerColor, setSketchPickerColor] = useState("#af6024");
  const submitUserColor = (event) => {
    event.preventDefault();
    submitNewColor( props.userInformation.card_name , "color" , sketchPickerColor );
  }
  const submitNewColor = (card_name,item, value)=>{
    const apiUrl =`${BaseURL}${Folder}/pannel/myPage/addNewItemLink.php`; // Replace with your API endpoint URL
    // const apiUrl ="http://localhost:8000/pannel/myPage/addNewItemLink.php"; // Replace with your API endpoint URL
    const jwtToken = JSON.parse(localStorage.getItem("login")).acess; // Replace with your JWT token
    // Perform AJAX request to send data to the API
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        card_name,
        item,
        value
      }),
    })
      .then((response) => response.text())
      .then((data) => {
        // console.log(data);
        props.getitems();
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(()=>{
    // console.log(sketchPickerColor);
  },[sketchPickerColor])


  return (
    <div className='setting-items' dir='rtl'>
      <div className='add-input colorInput'>
          <SketchPicker
            onChange={(color) => {
              setSketchPickerColor(color.hex);
          }}
          color={sketchPickerColor}
        />
          <button className="add-edit settingEdit" style={{ backgroundColor: props.color }} onClick={submitUserColor}>تایید و ذخیره اطلاعات</button>
      </div>
    </div>
  )
}

import DashboardAside from "./DashboardAside";
import "../../Style/Dashboard/Dashboard.css"
import User from "../../images/DashBoard/user-edit.png"
import back7 from "../../images/DashBoard/Dashboard/back7.png"
import Ellipse19 from "../../images/DashBoard/DashboardAside/Ellipse19.png"
import { useEffect, useState } from "react";
import axios from "axios";
import { BaseURL, Folder } from "../../Addresses";

const Dashboard = (props) => {
    const [inputs,setInputs] = useState({
        fisrtName:"",
        lastName:"",
        number:"",
        Adress:"",
    });
    const [error, setError] = useState('');
    const [alert, setAlert] = useState('');

    useEffect(() => {
        document.title = "FarazCart | Dashboard"
        props.showNavbar(false);
        fetchData();
    },[]);


    const fetchData =() => {
        const getDataUrl = `${BaseURL}${Folder}/pannel/Virayesh/GetData.php`;
        // const getDataUrl = 'http://localhost:8000/pannel/Virayesh/GetData.php'; // Replace with your API endpoint URL
        const jwtToken = JSON.parse(localStorage.getItem('login')).acess; // Replace with your JWT token
        fetch(getDataUrl, {
            method: 'POST',
            headers: {
                "Authorization": jwtToken,
            }
        })
        .then(response => response.json())
        .then(data => {
            setInputs({...data[0]});
        })
        .catch(error => {
            setError("مشکلی در دریافت اطلاعات از سمت سرور وجود دارد ، لطفا مجددا وارد شوید");
            setAlert('')
        });
    }

    const submitHandler = (event) =>{
        event.preventDefault();
        const setDataUrl = `${BaseURL}${Folder}/pannel/Virayesh/UpdateData.php`;
        // const setDataUrl = 'http://localhost:8000/pannel/Virayesh/UpdateData.php'; // Replace with your API endpoint URL
        const jwtToken = JSON.parse(localStorage.getItem('login')).acess; // Replace with your JWT token
        fetch(setDataUrl, {
            method: 'POST',
            headers: {
                'Authorization': jwtToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(inputs)
        })

            .then(response => response.json())
            .then(data => {
                setAlert(data)
                setError("")
            })
            .catch(error => {
                setError("مشکلی در ثبت اطلاعات وجود دارد.بعدا امتحان کنید")
                setAlert("")
            });

    }

    const inputHandler = (event) => {
        setInputs({
            ...inputs,
            [event.target.name]: event.target.value
        })
    }


    return (
        <div className="DashBoardPage">
            <img className="back7" src={back7} alt="background" />
            <img className="Ellipse19" src={Ellipse19}  alt="background" />
            <div className="EditProfile">
                <div className="titleEditProfilePage">
                    <h1 className="titleEditProfilePageText"> ویرایش پروفایل </h1>
                    <img src={User} alt="userIcon" />
                </div>
                <h3 className="error">{error.length ? error : ""}</h3>
                <h3 className="alert">{alert.length ? alert : ""}</h3>
                <form onSubmit={submitHandler}>
                    <div className="gridInForm">
                        <div className="DivItemInputs">
                            <label className="titleInputDashBoardEditProfile"> نام <span className="redStar">*</span></label>
                            <input type="text" name="fisrtName" onChange={inputHandler} value={inputs.fisrtName} />
                        </div>
                        <div className="DivItemInputs">
                            <label className="titleInputDashBoardEditProfile"> نام خانوادگی  <span className="redStar">*</span></label>
                            <input type="text" name="lastName" onChange={inputHandler} value={inputs.lastName} />
                        </div>
                        <div className="DivItemInputs">
                            <label className="titleInputDashBoardEditProfile"> تلفن همراه <span className="redStar">*</span></label>
                            <input placeholder="09" value={inputs.number}/>
                        </div>
                        <div className="DivItemInputs">
                            <label className="titleInputDashBoardEditProfile"> آدرس <span className="redStar">*</span></label>
                            <textarea className="ParagraphInput" name="Adress" onChange={inputHandler} value={inputs.Adress} />
                        </div>
                        <div className="DivItemInputs submitBtn">
                            <button className="submitDashBoardPage" type="submit">ثبت</button>
                        </div>
                    </div>
                </form>
                {/* <div className="CompleteProfile">
                    <div className="Percent">
                        <span className="PercentNum"> 100% </span>
                    </div>
                    <p>
                    تکمیل پروفایل
                    </p>
                </div> */}
            </div>
            <DashboardAside address={"Dashboard"} />
        </div>
    )
}
export default Dashboard;
import DashboardAside from "./DashboardAside";
import "../../Style/Dashboard/UserCart.css"
import banner from "../../images/DashBoard/Buy/Rectangle 317.png";
import back10 from "../../images/DashBoard/Buy/back10.png";
import Ellipse19 from "../../images/DashBoard/DashboardAside/Ellipse19.png"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BaseURL, Folder } from "../../Addresses";

const DashboardUserCart = () => {
    const [carts,setCarts] = useState([]);
    useEffect(() => {
        document.title = "FarazCart | Cart";
    })
    useEffect(()=>{
        getCarts();
    },[])
    const getCarts = () =>{
        const apiUrl = `${BaseURL}${Folder}/pannel/MyCard/GetMyCards.php`; // Replace with your API endpoint URL
        // const apiUrl = 'http://localhost:8000/pannel/MyCard/GetMyCards.php'; // Replace with your API endpoint URL
        const jwtToken = JSON.parse(localStorage.getItem('login')).acess; // Replace with your JWT token
        // Perform AJAX request to send data to the API
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': jwtToken,
                'Content-Type': 'application/json'
            },
            body:""
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data);
            setCarts(data);
        })
        .catch(error => {
            // console.log(error);
        });
    }


    return (
        <div className="userCartMain">
            <img className="Ellipse19" src={Ellipse19} />
            <div className="flx">
                {
                    carts.length ?
                    carts.map(item  =>
                    <div className="UserCArtContent">
                        <img src={item.image} alt="Banner" />
                        <div className="UserCArtText">
                            <Link className="link" to={`/editPage/${item.card_name}`}>
                                ویرایش
                            </Link>
                            <h2>
                                {item.card_name}
                            </h2>
                        </div>
                    </div>
                 )
                    :
                    <p>bir</p>
                }
            </div>
            <img className="back10" src={back10} alt="Banner" />
            <DashboardAside address={"UserCart"} />
        </div>
    )
}
export default DashboardUserCart;
import personOne from "../../images/HomePage/nazarat/Ellipse 38 (2).png"
import personTwo from "../../images/HomePage/nazarat/Ellipse 39 (1).png"
import personThree from "../../images/HomePage/nazarat/Ellipse 40.png"
import Ellipse13 from "../../images/HomePage/nazarat/Ellipse13.png"
import Ellipse13_2 from "../../images/HomePage/nazarat/Ellipse13_2.png"
import Boy from "../../images/HomePage/nazarat/boy.png"
import Girl from "../../images/HomePage/nazarat/girl.png"

import back4 from "../../images/HomePage/nazarat/back4.png"
import back4_2 from "../../images/HomePage/nazarat/back4_2.png"
import "../../Style/HomePage/Nazarat.css"
const Nazarat = () => {
    return (
        <section className="nazarat_section">
            <img className="back4" src={back4} alt="back4" />
            <img className="back4_2" src={back4_2} alt="back4_2" />
            <img className="Ellipse13" src={Ellipse13} alt="Ellipse13" />
            <img className="Ellipse13_2" src={Ellipse13_2} alt="Ellipse13_2" />
            <div className="titleNazarat">
                <h2>
                    نظرات کاربران فراز کارت
                </h2>
            </div>
            <div className="Comments">

                <div className="Comment">

                    <span>
                        فرهاد محمودی
                        <img className="profileComment" src={Boy} alt="farazcart" />
                    </span>
                    <p>
                        امکانات عالی ، رابط کاربری خوب ، خفن بودن تکنولوژی ، راحتی کار باهش ، تغییر پذیر بودنش درهر لحظه ، قیمت عالی و کیفیت بالای این محصول باعث شد فراز کارت رو انتخاب کنم
                    </p>
                </div>
                <div className="Comment">
                    <span>
                        یاسمن مرادی
                        <img className="profileComment" src={Girl} alt="farazcart" />
                    </span>
                    <p lang="en">
                        واقعا به نظر من تکتولوژی خیلی خفنی هستش ، من که به شدت دوست دار محیط زیست ام همیشه با کارت ویزیت های کاغذی مشکل داشتم.فرازکارت واقعا ارزش مهمی رو خلق کرده
                    </p>
                </div>

                <div className="Comment">
                    <span>
                        فائزه رضایی
                        <img className="profileComment" src={Girl} alt="farazcart" />
                    </span>
                    <p>
                        از عالی بودن محصول که بگذریم پشتیبانی و همکاری عالی عوامل جای تعریف داره
                    </p>
                </div>

            </div>

        </section>
    )
}
export default Nazarat;
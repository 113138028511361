import React, { useState } from 'react'
import { BaseURL, Folder } from '../../Addresses';

export default function MenuPage(props) {
  const [userNewInfo , setUserNewInfo] = useState('')
  
  const submituserLinks = (event) => {
    event.preventDefault();
    // console.log(props);
    submitNewLink(props.userInformation.card_name,props.selectItem.itemName,userNewInfo);
  }

  const submitNewLink = (card_name,item , value)=>{
    const apiUrl =`${BaseURL}${Folder}/pannel/myPage/addNewItemLink.php`; // Replace with your API endpoint URL
    // const apiUrl ="http://localhost:8000/pannel/myPage/addNewItemLink.php"; // Replace with your API endpoint URL
    const jwtToken = JSON.parse(localStorage.getItem("login")).acess; // Replace with your JWT token
    // Perform AJAX request to send data to the API
    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        card_name,
        item,
        value
      }),
    })
      .then((response) => response.text())
      .then((data) => {
        // console.log(data);
        props.getitems();
      })
      .catch((error) => {
        // console.log(error);
      });
  };


  return (
    <div className='edit-items' dir='rtl'>
      <div className='add-input'>
          <div style={{ display: "flex", alignItems: "flex-end",width:"100%" }}>
              {/* <span style={{display:"flex"}} className='edit-input-icon'> */}
              {/* <img className="info-logo2" src={props.selectItem.icon} alt="logo" /> */}
              {/* </span> */}
              <p className='edit-title'>{props.selectItem.addTitle} :</p>
          </div>
          <input type="text" value={userNewInfo} onChange={e => setUserNewInfo(e.target.value)}  />
          <button className="add-edit" style={{ backgroundColor: props.color }} onClick={submituserLinks}>تایید و ذخیره اطلاعات</button>
      </div>
    </div>
  )
}

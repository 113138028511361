import mainBanner from "../../images/DashBoard/Buy/Rectangle 317.png"
import thirdBanner from "../../images/DashBoard/Buy/Rectangle 315.png"
import FourthBanner from "../../images/DashBoard/Buy/Rectangle 316 (1).png"
import upload from "../../images/DashBoard/Buy/upload-01 (1).png"
import Nano from "../../images/DashBoard/Buy/nano_o-min.png"
import Dark from "../../images/DashBoard/Buy/dark_o-min.png"
import Gold from "../../images/DashBoard/Buy/gold_o-min.png"
import White from "../../images/DashBoard/Buy/faraz_o-min.png"

import DashboardAside from "./DashboardAside";
import "../../Style/Dashboard/EditCart.css"
import back11EditCart from "../../images/DashBoard/Buy/back11.png"
import Ellipse19 from "../../images/DashBoard/DashboardAside/Ellipse19.png"
import Ellipse21 from "../../images/DashBoard/Buy/Ellipse21.png"
import { Children, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {BaseURL , Folder} from '../../Addresses';

const EditCart = () => {
    const navigate = useNavigate();
    const [inputs , setInputs] = useState({
        "design" : Nano,
        "domain": localStorage.getItem('domain')
    });
    const [error , setError] = useState(false);
    const [fileUploadAdress , setFileUploadAdress] = useState("");
    const [fileUpload , setFileUpload] = useState("");

    useEffect(() => {
        document.title = "FarazCart | Editing"
    })
    const fileInputHandler = (event) =>{
        setFileUploadAdress(event.target.value);
        setFileUpload(event.target.files[0]);        
    }

    const inputHandler = (event) =>{
        setInputs({
            ...inputs,
            [event.target.name] : event.target.value
        });
    }

    const inputHandler2 = (event) =>{
        setInputs({
            ...inputs,
            "design" : event.target.src
        });
    }

    const submitHandler = (event) =>{
        event.preventDefault();
        const apiUrl = `${BaseURL}${Folder}/pannel/KharidCart/getCartInfo.php`; // Replace with your API endpoint URL
        // const apiUrl = 'http://localhost:8000/pannel/KharidCart/getCartInfo.php'; // Replace with your API endpoint URL
        const jwtToken = JSON.parse(localStorage.getItem('login')).acess; // Replace with your JWT token

        const formData = new FormData();
        formData.append("image", fileUpload);
        formData.append("user", JSON.stringify(inputs));

        const headers = new Headers();
        headers.append("Authorization", jwtToken);
        // headers.append("Content-Type", "application/json");
        fetch(apiUrl, {
            method: 'POST',
            headers: headers,
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data);
            if(data.success){
                navigate("/Userpardakht");
                setError(false);
            }else{
                setError("مشکلی در ثبت اطلاعات وجود دارد . بعدا امتحان کنید")
            }
        })
        .catch(error => {
            // console.log(error);
            setError("مشکلی در ثبت اطلاعات وجود دارد . بعدا امتحان کنید")
        });
    }



    return (
        <div className="EditCartPage">
            <img className="Ellipse21" src={Ellipse21} alt="backgroundColor" />
            <img className="Ellipse19" src={Ellipse19} alt="backgroundColor" />
            <img className="back11EditCart" src={back11EditCart} alt="back11" />
            <div className="mainEditart">
                <h2 className="titlPageEditCart">
                    مشخصات
                </h2>
                <form enctype="multipart/form-data" onSubmit={submitHandler}>
                    <div className="boxInput">
                        <div className="eachInput">
                        <input type="text" onChange={inputHandler} name="caption" placeholder="مثال:  عکاس و گرافیست" />
                            <label>
                                توضیحات
                            </label>
                        </div>
                        <div className="eachInput">
                            <input type="text" onChange={inputHandler} name="fullName" placeholder="مثال: مرتضی رضوی" />
                            <label>
                                نام
                            </label>
                        </div>
                    </div>
                    <div className="ChooseDesign">
                        <h2 className="ChooseDesignTitle">
                            انتخاب طرح
                        </h2>
                        <div className="ChooseDesignImgBox">
                            <div className="desigens">
                                <img className="SelectedDesign" src={inputs.design} alt="banner FarazCart" />
                                <span>طرح انتخاب شده</span>
                            </div>
                            <div className="AnotherPlan">
                                {/* <div className="Designers SimpleDesign"><p>طرح ساده </p></div> */}
                                {/* <input onChange={inputHandler} type="radio" name="design" value={"simple"}/> */}
                                <div className="marginx desigens">
                                    <img onClick={inputHandler2}  data_name="design" className="Designers" src={Nano} value={Nano} alt="FarazCart" />
                                    <span>طرح ارغوان</span>
                                    {/* <input onChange={inputHandler} type="radio" name="design" value={Nano}/> */}
                                </div>

                                <div className="marginx desigens">
                                    <img onClick={inputHandler2}  data_name="design" className="Designers" src={Dark} value={Dark} alt="FarazCart" />
                                    <span>طرح سایه</span>
                                    {/* <input onChange={inputHandler} type="radio" name="design" value={Dark}/> */}
                                </div>

                                <div className="marginx desigens">
                                    <img onClick={inputHandler2}  data_name="design" className="Designers" src={White} value={White} alt="FarazCart" />                                
                                    <span>طرح فراز</span>
                                    {/* <input onChange={inputHandler} type="radio" name="design" value={White}/> */}
                                </div>

                                <div className="marginx desigens">
                                    <img onClick={inputHandler2}  data_name="design" className="Designers" src={Gold} alt="FarazCart" />                                
                                    <span>طرح آفاق</span>
                                    {/* <input onChange={inputHandler} type="radio" name="design" value={Gold}/> */}
                                </div>

                            </div>

                        </div>
                        {error ? 
                            <span className="error">
                                {error}
                            </span>
                        : " "}
                    </div>
                    <div className="fileUploadBox">
                        <div className="fileUpload">
                            <input type="file" name="file" id="fileUpload" onChange={fileInputHandler} />
                            <div className="fileUploadInput">
                                <span className="fileAddress">{fileUploadAdress}</span>
                                <label for="fileUpload">
                                    انتخاب
                                </label>
                            </div>
                        </div>
                        <span>عکس پروفایل که باید روی طرح انتخابی چاپ بشه رو ازینجا آپلود کن</span>
                        <span>یا اگه طرح دیگه بخوای از این بخش میتونی طرح مورد علاقتو برامون آپلود کنی تا روی کارتت چاپ شه</span>
                    </div>
                    <button className="ConfirmSendEditCart" type="submit" onClick={submitHandler}>تایید و ارسال فایل</button>
                </form>
            </div>
            <DashboardAside address={"EditCart"} />
        </div>
    )
}
export default EditCart;
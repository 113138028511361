import { Link } from 'react-router-dom'
import zarinpal from "../../images/HomePage/Footer/zarin.png"
import location from "../../images/HomePage/Footer/Icon.png"
import ig from "../../images/HomePage/Footer/Instagram.png"
import Line from "../../images/HomePage/Footer/Line.png"
import what from "../../images/HomePage/Footer/whatsapp.png"
import Vector from "../../images/HomePage/Footer/Vector.png"
import aghayePardakht from "../../images/HomePage/Footer/aghayepardakht-1 1.png"
import faraz from "../../images/HomePage/Footer/IMG_20230305_165033_781 1.png"
import "../../Style/HomePage/Footer.css"
const Footer = () => {
    const aqayePardakht = (e)=>{
        window.open('https://panel.aqayepardakht.ir/trustGateway/11762', '_blank', 'width=400, height=600, scrollbars=no, resizable=no');
    }
    return (
        <footer>
            <div className='Allfooter'>
                <div className='footerOptions'>
                    <div className='License dinInFooter'>
                        <p> مجوز و نماد ها <img className='Line' src={Line} alt="Line" /> </p>
                        <a href="javascript:void(0)" onClick={aqayePardakht}>
                            <img
                                style={{ borderRadius: '0px', marginRight: '10px' }}
                                src="https://panel.aqayepardakht.ir/trustlogo/1.svg"
                                alt="Trust Logo"
                            />
                        </a>
                    </div>
                    <div className='Communication dinInFooter'>
                        <p> راه های ارتباطی <img className='Line' src={Line} alt="Line" /> </p>
                        <div>
                            <a href="https://instagram.com/farazcart.ir" target="_blank" rel="noopener noreferrer">farazcart.ir</a>
                            <img className='instagram' src={ig} alt="instagram" />
                        </div>
                        <div>
                            <a href="tel:+98999953235" target="_blank" rel="noopener noreferrer">0999953235</a>
                            <img className='whatsapp' src={what} alt="whatsapp" />
                        </div>
                        <div>
                            <Link to="https://openai.com/blog/chatgpt" target="_blank" rel="noopener noreferrer">گیلان ، رشت ، میدان صابرین</Link>
                            <img className='location' src={location} alt="location" />
                            <img className='Vector' src={Vector} alt="Vector" />
                        </div>
                    </div>
                    <div className='QuickAccess dinInFooter'>
                        <p> دسترسی سریع <img className='Line' src={Line} alt="Line" /> </p>
                        <Link to="/AboutUs">معرفی</Link>
                        <Link to="/NfcYab">یاب NFC</Link>
                        <Link to="/AloFarazCart">الو فراز؟</Link>
                    </div>
                </div>
                <div className='Capschen'>
                    <div className='CapschenTitle'>
                        <img className='farazCartImg' src={faraz} alt="farazCart" />
                        <p>  <span className='farazcart'>فراز کارت </span> ، همراه هوشمند تو!</p>
                    </div>
                    <div className='CapschenText'>
                        فراز  کارت و تیم فراز در تابستان سال 1401 با هدف توسعه روش های نوین ارتباطات شروع به فعالیت کرد و در خدمت شماست.
                    </div>
                    <div className='location2Div' >
                    <Link className='locationText2' to="https://openai.com/blog/chatgpt" target="_blank" rel="noopener noreferrer">گیلان ، رشت ، میدان صابرین</Link>
                            <img className='location2' src={location} alt="location" />
                    </div>
                </div>
            </div>
        </footer >
    )
}
export default Footer;
import { useEffect } from "react";
import { Link, json } from "react-router-dom";
import "../../Style/Sabtenam/Login.css";
import Ellipse16 from '../../images/Sabtenam/Login/Ellipse16.png';
import Ellipse17 from '../../images/Sabtenam/Login/Ellipse17.png';
import Ellipse18 from '../../images/Sabtenam/Login/Ellipse18.png';
import back5 from '../../images/Sabtenam/Login/back5.png';
import back6 from '../../images/Sabtenam/Login/back6.png';
import logoFarazLog from '../../images/Sabtenam/Login/logoFaraz.png';
import farazCartLogin from '../../images/Sabtenam/Login/farazCart.png';
import axios from "axios";
import { useState } from "react";
import Users from "../../Json/NewContacts.json";
import { useNavigate } from "react-router-dom";
import { BaseURL,Folder } from "../../Addresses";


const Login = (props) => {
    const [number, setNumber] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "FarazCart | Login";
        props.showLoginBtn(false)
        window.scrollTo(0, 0); // حرکت به بالای صفحه
    })
    const handleChange = (e) => {
        const { value, maxLength } = e.target;
        const number = value.slice(0, maxLength);
        setNumber(number);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const requestData = {
            number: number
        };
        // Convert the data to a JSON string
        const jsonData = JSON.stringify(requestData);
        // API URL

        const apiUrl = `${BaseURL}${Folder}/Login/Login.php`;
        // const apiUrl = 'http://localhost:8000/Login/Login.php';

        // Fetch options for the POST request
        const fetchOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: jsonData
        };
        // Make the Fetch request
        fetch(apiUrl, fetchOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                // Handle the response data
                localStorage.setItem('login',JSON.stringify(data));
                navigate('/Payam')
            })
            .catch(error => {
                console.log("ee ",error);
                setError("مشکلی در ارسال پیامک وجود دارد.لطفا مجددا امتحان کنید")
                // console.error('Error:', error);
            });
    
    }

    return (
        <main className="loginContent">
            <div className="LoginDiv">
                <p className="OwnershipSite"> تمام حقوق برای فراز محفوظ است </p>
                <img className='logoFarazLog' src={logoFarazLog} alt="logoFarazLog" />
                <img className='Ellipse16' src={Ellipse16} alt="Ellipse16" />
                <img className='Ellipse17' src={Ellipse17} alt="Ellipse17" />
                <img className='Ellipse18' src={Ellipse18} alt="Ellipse18" />
                <img className='back5' src={back5} alt="back5" />
                <img className='back6' src={back6} alt="back6" />
                <div className="backWhiteRispan">
                    <img className='farazCartLogin' src={farazCartLogin} alt="farazCartLogin" />
                    <div className="LoginBox">
                        <img className='Ellipse18_2' src={Ellipse18} alt="Ellipse18_2" />
                        <h3>  ورود/ثبت نام </h3>
                        <form onSubmit={handleSubmit}>
                            <div className="userPass">
                                <label htmlFor="number">
                                    شماره همراه خود را برای ورود وارد کنید
                                </label>
                                <input type="number" name="user" id="user" onChange={handleChange} value={number} maxLength="11" required />
                                <p className="mainError">{error.length ? error : ""}</p>
                            </div>
                            <div>
                                <button className="RegisterLoginClick LoginClick" type="submit" name="submit" >ورود</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
}
export default Login;
import HomePage from "../Component/HomePage/HomePage";
import Login from "../Component/Sabtenam/Login";
import NfcYab from "../Component/NFCyab/NfcYab";
import AloFarazCart from "../Component/Alo/AloFarazCart";
import Register from "../Component/Sabtenam/Register";
import Payam from "../Component/Sabtenam/Auth/Payam";
import Dashboard from "../Component/Dashboard/Dashboard";
import DashboardUserCart from "../Component/Dashboard/UserCart";
import DashboardBuyCart from "../Component/Dashboard/UserBuy";
import DashboardUserInfo from "../Component/Dashboard/UserInfo";
import UserPardakht from "../Component/Dashboard/Userpardakht";
import EditCart from "../Component/Dashboard/EditCart.js";
import MyPage from "../Component/MyPage/MyPage";
import { Route, Routes } from "react-router-dom";
import { Component, useEffect, useState } from "react";
import MainAlo from "../Component/Alo/MainAlo";
import MenuPage from "../Component/MenuPage/MenuPage";
import PrivateRoute from "./PrivateRoute.js";
import UserPage from "../Component/MyPage/UserPage.jsx";
import AboutUs from "../Component/AboutUs/AboutUs.jsx";
import Success from "../Component/Dashboard/Success.js";
import Failed from "../Component/Dashboard/Failed.js";



const AllRoutes = (props) => {
  const [isAuthenticated,setIsAuthenticated] = useState(localStorage.getItem('auth'));
  return (
    <div>
      <Routes>
        {/* <Route path="/Register" element={<Register showLoginBtn={props.showLoginBtn} />} /> */}
        <Route path="/*" element={<UserPage />} />
        <Route path="/NfcYab" element={<NfcYab showLoginBtn={props.showLoginBtn} />} />
        <Route path="/AloFarazCart" element={<AloFarazCart showLoginBtn={props.showLoginBtn} />} />
        <Route path="/AboutUs" element={<AboutUs showLoginBtn={props.showLoginBtn} />} />
        <Route path="/Login" element={<Login showLoginBtn={props.showLoginBtn} />} />

        
        <Route path="/Payam" element={<Payam setAuth={setIsAuthenticated} showLoginBtn={props.showLoginBtn} />} />
        <Route path="/" element={<HomePage setAuth={setIsAuthenticated}  showNavbar={props.showNavbar} showLoginBtn={props.showLoginBtn} />} />
        <Route path="/editPage/*" element={<PrivateRoute setAuth={setIsAuthenticated} auth={isAuthenticated}><MyPage/></PrivateRoute>} />
        <Route path="/Dashboard" element={<PrivateRoute setAuth={setIsAuthenticated} auth={isAuthenticated}> <Dashboard showNavbar={props.showNavbar}/></PrivateRoute>} />
        <Route path="/buyCart" element={<PrivateRoute setAuth={setIsAuthenticated} auth={isAuthenticated}> <DashboardBuyCart/></PrivateRoute>} />
        <Route path="/MyCarts" element={<PrivateRoute setAuth={setIsAuthenticated} auth={isAuthenticated}> <DashboardUserCart/></PrivateRoute>} />
        <Route path="/AllInfo" element={<PrivateRoute setAuth={setIsAuthenticated} auth={isAuthenticated}> <DashboardUserInfo/></PrivateRoute>} />
        <Route path="/Userpardakht" element={<PrivateRoute setAuth={setIsAuthenticated} auth={isAuthenticated}> <UserPardakht/></PrivateRoute>} />
        <Route path="/EditCart" element={<PrivateRoute setAuth={setIsAuthenticated} auth={isAuthenticated}> <EditCart/></PrivateRoute>} />
        <Route path="/myPage" element={<PrivateRoute setAuth={setIsAuthenticated} auth={isAuthenticated}> <MyPage/></PrivateRoute>} />
        <Route path="/success" element={<PrivateRoute setAuth={setIsAuthenticated} auth={isAuthenticated}> <Success/></PrivateRoute>} />
        <Route path="/failed" element={<PrivateRoute setAuth={setIsAuthenticated} auth={isAuthenticated}> <Failed/></PrivateRoute>} />
      </Routes>
    </div>
  );
}

// let routes = [
//   { path: "/", element: <HomePage /> },
//   { path: "/NfcYab", element: <NfcYab /> },
//   { path: "/Login", element: <Login /> },
//   { path: "/Register", element: <Register /> },
//   { path: "/Payam", element: <Payam /> },
//   { path: "/Dashboard", element: <Dashboard /> },
//   { path: "/buyCart", element: <DashboardBuyCart /> },
//   { path: "/MyCarts", element: <DashboardUserCart /> },
//   { path: "/AllInfo", element: <DashboardUserInfo /> },
//   { path: "/Userpardakht", element: <UserPardakht /> },
//   { path: "/EditCart", element: <EditCart /> },
//   { path: "/myPage", element: <MyPage /> },
//   { path: "/AloFarazCart", element: <AloFarazCart /> },
// ];


export default AllRoutes

import User from "../../images/DashBoard/Buy/profile-circle.png"
import dollar from "../../images/DashBoard/Buy/dollar-circle.png"
import Tik from "../../images/DashBoard/Buy/Group 35858.png"
import Rec from "../../images/DashBoard/Buy/Rectangle 317.png"
import back8 from "../../images/DashBoard/Buy/back8.png"
import back9 from "../../images/DashBoard/Buy/back9.png"
import Ellipse19 from "../../images/DashBoard/DashboardAside/Ellipse19.png"
import Ellipse21 from "../../images/DashBoard/Buy/Ellipse21.png"
import DashboardAside from "./DashboardAside";
import "../../Style/Dashboard/UserBuy.css"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { BaseURL, Folder } from "../../Addresses"

const DashboardBuyCart = () => {

    const [inputs , setInputs] = useState({subDOmain:""});
    const [error , setError] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "FarazCart | Buy"
    })
    const inputHandler = (event) =>{
        setInputs({[event.target.name] : event.target.value});
    }
    const submitHandler = (event) =>{
        event.preventDefault();
        let nextStep;
        const apiUrl = `${BaseURL}${Folder}/pannel/KharidCart/GetSubDomainName.php`; // Replace with your API endpoint URL
        const jwtToken = JSON.parse(localStorage.getItem('login')).acess; // Replace with your JWT token
        // Perform AJAX request to send data to the API
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': jwtToken,
                'Content-Type': 'application/json'

            },
            body: JSON.stringify(inputs)
        })
        .then(response => response.text())
        .then(data => {
            // console.log(data);
            nextStep = JSON.parse(data).situation;
            // console.log(nextStep);
            if(nextStep==="true"){
                localStorage.setItem('domain',inputs.subDOmain)
                navigate("/EditCart")
            }else{
                setError("این نام کاربری قبلا انتخاب شده است")
            }
        })
        .catch(error => {
            setError("مشکلی در ثبت اطلاعات وجود دارد . بعدا امتحان کنید")
        });
    }


    return (
        <div className="UserBuyPage">
            <img className="Ellipse19" src={Ellipse19} alt="backGround" />
            <img className="back8" src={back8} alt="backGround" />
            <img className="Ellipse21" src={Ellipse21} alt="backGround" />
            <div className="UserBuyPageContent">
                <img className="back9" src={back9} alt="backGround" />
                <div className="levelsUserBuyPage">
                    <img src={User} alt="userIcon" />
                    <img src={dollar} alt="butIcon" />
                    <img src={Tik} alt="tickIcon" />
                </div>
                <div>
                    <img className="banerUserBuyPage" src={Rec} alt="cardPhoto" />
                </div>
                <form onSubmit={submitHandler} className="formUserBuyPage">
                    <label>نام کاربری</label>
                    <input placeholder=" مثال : ali " name="subDOmain" value={inputs.subDOmain} type="text" onChange={inputHandler} />
                    {error ? 
                    <span className="error">
                        {error}
                    </span>
                    : " "}
                    <span>
                        نکته : نام کاربری که اینجا ثبت میشود آدرس صفحه اختصاصی شماست
                        مانند : faracard.ir/ali
                    </span>
                    <button className="buttonUserBuyPage" type="submit">
                        ادامه
                    </button>
                </form>
            </div>
            <DashboardAside address={"UserBuy"} />
        </div>
    )
}
export default DashboardBuyCart;